var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.simInfo)?_c('div',{staticClass:"auth",attrs:{"id":"Auth"}},[_c('div',{staticClass:"auth__container"},[_c('div',{staticClass:"auth_span",staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticClass:"img_logo",staticStyle:{"max-width":"50%"},attrs:{"src":_vm.$url + _vm.simInfo.logo,"alt":""}})]),_c('div',{staticClass:"auth__head"},[_c('div',{staticClass:"auth__head__type"},[_vm._v(" "+_vm._s(_vm.type === 1 ? _vm.simInfo.login_text ? _vm.simInfo.login_text : "Войти" : _vm.type === 2 ? _vm.simInfo.signin_text ? _vm.simInfo.signin_text : "Зарегистрироваться" : _vm.simInfo.pass_recover_text ? _vm.simInfo.pass_recover_text : "Восстановить пароль")+" ")])]),(
        _vm.simInfo.group_info &&
        _vm.type < 3 &&
        _vm.simInfo &&
        (_vm.simInfo.group_info.auth_facebook_key || _vm.simInfo.group_info.auth_vk_key)
      )?_c('div',{staticClass:"mt-2 w-100"},[(_vm.simInfo.group_info.auth_facebook_key)?_c('Facebook',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"45"},nativeOn:{"click":function($event){return _vm.socialInit('facebook')}}}):_vm._e(),(_vm.simInfo.group_info.auth_vk_key)?_c('VK',{staticStyle:{"cursor":"pointer"},attrs:{"size":"45"},nativeOn:{"click":function($event){return _vm.socialInit('vk')}}}):_vm._e(),(_vm.simInfo.group_info.auth_facebook_key || _vm.simInfo.group_info.auth_vk_key)?_c('hr'):_vm._e()],1):_vm._e(),_c('form',{staticClass:"auth__body",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',[(_vm.type <= 3)?_c('FormInput',{attrs:{"valid":_vm.validEmail,"error":_vm.simInfo.required_text ? _vm.simInfo.required_text : _vm.errorMailMessage,"title":_vm.simInfo.email_text ? _vm.simInfo.email_text : 'E-mail',"placeholder":_vm.simInfo.placeholder_email_text
              ? _vm.simInfo.placeholder_email_text
              : 'Введите e-mail'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e(),(_vm.type < 3 || _vm.type === 4)?_c('FormInput',{attrs:{"valid":_vm.validPass,"error":_vm.simInfo.min_password_text ? _vm.simInfo.min_password_text : _vm.errorPassMessage,"type":"password","title":_vm.simInfo.password_text ? _vm.simInfo.password_text : 'Пароль',"placeholder":_vm.simInfo.password_placeholder_text
              ? _vm.simInfo.password_placeholder_text
              : 'Введите пароль'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e(),(_vm.type === 2 || _vm.type === 4)?_c('FormInput',{attrs:{"valid":_vm.simInfo.repeat_password_text ? _vm.simInfo.repeat_password_text : _vm.validRePass,"error":_vm.errorRePassMessage,"type":"password","title":_vm.simInfo.repeat_password_text
              ? _vm.simInfo.repeat_password_text
              : 'Повторите пароль',"placeholder":_vm.simInfo.password_placeholder_text
              ? _vm.simInfo.password_placeholder_text
              : 'Введите пароль'},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}):_vm._e(),(
            _vm.type === 2 &&
            _vm.simInfo &&
            _vm.simInfo.agreement_url &&
            _vm.simInfo.data_processing_url &&
            _vm.simInfo.agreement_url != '' &&
            _vm.simInfo.data_processing_url != ''
          )?_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"id":"checkbox-1","name":"checkbox-1","value":"accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Я принимаю "),_c('a',{attrs:{"href":_vm.simInfo.agreement_url,"target":"_blank"}},[_vm._v("правила пользования сервисом")]),_vm._v(" и "),_c('a',{attrs:{"href":_vm.simInfo.data_processing_url,"target":"_blank"}},[_vm._v(" политику обработки персональных данных")])]):_vm._e()],1),_c('div',[_c('FormButton',{staticStyle:{"margin-bottom":"10px"},attrs:{"inactive":_vm.isaccept,"loading":_vm.formLoading,"text":_vm.type === 1
              ? _vm.simInfo.login_text
                ? _vm.simInfo.login_text
                : 'Войти'
              : _vm.type === 2
              ? _vm.simInfo.signin_text
                ? _vm.simInfo.signin_text
                : 'Зарегистрироваться'
              : _vm.type === 3
              ? _vm.simInfo.forget_pass_btn_text
                ? _vm.simInfo.forget_pass_btn_text
                : 'Сменить пароль'
              : 'Cменить пароль',"size":"full-width","type":"submit"}}),_c('div',{staticClass:"auth__change"},[(_vm.type === 1)?_c('div',{on:{"click":function($event){_vm.type = 2}}},[_vm._v(" "+_vm._s(_vm.simInfo.signin_text ? _vm.simInfo.signin_text : "Зарегистрироваться")+" ")]):_vm._e(),(_vm.type > 1)?_c('div',{on:{"click":function($event){_vm.type = 1}}},[_vm._v(" "+_vm._s(_vm.simInfo.login_text ? _vm.simInfo.login_text : "Войти")+" ")]):_vm._e(),(_vm.type === 1)?_c('div',{on:{"click":function($event){_vm.type = 3}}},[_vm._v(" "+_vm._s(_vm.simInfo.forget_pass_btn_text ? _vm.simInfo.forget_pass_btn_text : "Забыли пароль?")+" ")]):_vm._e()])],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }